export const GOOGLE_API_URL = "https://www.googleapis.com/oauth2/v3/userinfo"


// json-server dummy endpoints

export const USERS = "/users"
export const ROLES_DATA = "/roles-data"
export const PERMISSSIONS = "/permissions"
export const POSTS = "/posts"
export const CLIENTS = "/client"

// deployed api endpoints

export const POST = "/post"
export const ROLE = "/role"
export const POST_SEARCH = "/post/search"
export const GROUP = "/group"
export const COMMENT = "/comment"
export const REPLY = "/reply"

