import React, { useState, useEffect, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router'
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import { ThemeProvider, createTheme } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Error from './components/pages/errorpage/Error'
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import HomeLayout from './components/layout/HomeLayout';
import { getToken } from './utils/utils';
// import Groups from './components/pages/groups/Groups';
// import Products from './components/pages/groups/Products';
// import YourFeed from './components/pages/yourfeed/YourFeed';

const Login = lazy(() => import("./components/pages/login/Login"))
// const HomeLayout = lazy(() => import("./components/layout/HomeLayout"))
const YourFeed = lazy(() => import("./components/pages/yourfeed/YourFeed"))
const Groups = lazy(() => import('./components/pages/groups/Groups'))
const DiscussionForum = lazy(() => import("./components/pages/discussionforum/DiscussionForum"))

// const Dashboard = lazy(() => import("./components/pages/dashboard/Dashboard"))
// const Roles = lazy(() => import("./components/pages/roles/Roles"))
// const Permissions = lazy(() => import("./components/pages/permissions/Permissions"))
// const Profiles = lazy(() => import("./components/pages/profiles/Profiles"))
// const Settings = lazy(() => import("./components/pages/settings/Settings"))
// const BulkUpload = lazy(() => import("./components/pages/bulkupload/BulkUpload"))




const App = () => {
  const font = "'Poppins', sans-serif";
  const theme = createTheme({
    typography: {
      fontFamily: font
    }
  })
  const [token, setToken] = useState(null);

  useEffect(() => {
    let tempToken = localStorage.getItem("token")
    setToken(tempToken)
    // localStorage.getItem("token") && navigate(-1)
  }, []);

  return (
    <GoogleOAuthProvider clientId="247734515468-1caral148vs24evoub2at4hb7ncugtb4.apps.googleusercontent.com">
      <ThemeProvider theme={theme}>
        <div>
          
            <Routes>
              {/* Private Routes */}
              <Route
                element={<PrivateRoute token={token} setToken={setToken} component={HomeLayout} />}
              >
                <Route path="/your-feed" element={<YourFeed/>} />
                <Route path="/your-feed/:groupId" element={<YourFeed/>} />
                <Route path="/discussion-forum" element={<DiscussionForum/>} />
                <Route path="/groups" element={<Groups/>} />
                <Route path='*' element={<Error />} />
              </Route>
              
              {/* Public Routes */}
              {/* <Route path='/' element={<PublicRoute token={token} setToken={setToken} component={MainHeader} />}> */}
                <Route path='/' element={<PublicRoute token={token} setToken={setToken} component={Login} />}/>
                <Route path='*' element={<Navigate to="/" />} />
              {/* </Route> */}
            </Routes>
          
        </div>
      </ThemeProvider>
    </GoogleOAuthProvider>
  )
}

export default App
