import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import Menu from "../../../routes/routesJSON/Menu.json"
import "./Sidebar.css";
// import MenuIcon from "@mui/icons-material/Menu";
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import HomeIcon from '@mui/icons-material/Home';
// import SecurityIcon from '@mui/icons-material/Security';
// import PersonIcon from '@mui/icons-material/Person';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
// import WebIcon from '@mui/icons-material/Web';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import ForumIcon from '@mui/icons-material/Forum';
// import profileImg from '../../../assets/images/profilePic.jpg'
import defaultProfile from "../../../assets/images/default-profile.png"
import { toast } from "react-toastify";
import { getLoggedInUserDetails } from "../../../utils/utils";
import { privateGet } from "../../../services/privateRequest";
import { ROLE } from "../../../services/apiEndpoints";


const Sidebar = ({setToken}) => {
  const loggedInUser = getLoggedInUserDetails();
  const userName = loggedInUser?.name;
  const [userRole, setUserRole] = useState("Software Developer");
  const [showSubMenuItems, setShowSubMenuItems] = useState(Menu.map(item => false));
  // const [windowSmall, setWindowSmall] = useState(false);

  const iconComponents = {
    // "Dashboard": <HomeIcon fontSize="small"/>,
    // "Roles": <PersonIcon fontSize="small"/>,
    // "Permissions": <SecurityIcon fontSize="small"/>,
    // "Bulk Upload": <DriveFolderUploadIcon fontSize="small"/>,
    // "Profiles": <AccountCircleIcon fontSize="small"/>,
    "your feed": <FeedOutlinedIcon fontSize="small"/>,
    "account settings": <SettingsIcon fontSize="small"/>,
    "groups": <GroupOutlinedIcon fontSize="small" />,
    "discussion forum": <ForumIcon fontSize="small" />,
    "logout": <LogoutOutlinedIcon fontSize="small" />
  }

  const getUserRole = async() => {
    if(!loggedInUser?.role){
      return
    }
    const res = await privateGet(`${ROLE}/${loggedInUser?.role}`)
    if(res?.data?.data?.role) {
        setUserRole(res?.data?.data?.role)
    }
  }

  useEffect(() => {
    getUserRole()
  }, [])

  // const headerIconComponents = {
  //   "roles & permissions": <SecurityIcon fontSize="medium"/>,
  //   "Pages": <WebIcon fontSize="medium" />,
  // }
  // const { hideSidebar, handleHideSidebar } = props;

  // useEffect(() => {
  //   if(window.innerWidth <= 1200){
  //     !hideSidebar && handleHideSidebar()
  //   }
  // }, [])

  return (
    <div className={`sidebar`}>
      <ul className="sidebar__ul sidebar__ul--1">
        <li className="sidebar__ul--li-head">
          {/* <span className={`sidebar__ul--li-head-span ${hideSidebar ? "small" : null}`}>
            {hideSidebar ? (
              <div>
                <MenuIcon
                  onClick={handleHideSidebar}
                  style={{ marginTop: "5vh" , pointerEvents: windowSmall && "none"}}
                />
              </div>
            ) : (
              <div style={{ marginTop: "5vh"}}>
                <KeyboardArrowLeftIcon
                  onClick={handleHideSidebar}
                  fontSize="medium"
                />
              </div>
            )}
          </span> */}
          <div className="sidebar-profile-details">
            <div>
              <img className="profile-image" src={defaultProfile} alt="no-profile-pic" />
            </div>
            <div className="profile-info">
              <p className="profile-name">{userName}</p>
              <p className="profile-role">{userRole}</p>
            </div>
          </div>
        </li>
      </ul>
      <hr className="sidebar-hr"/>
      <ul className={`sidebar__ul sidebar__ul--2`}>
        {Menu.map((header) => (
          <div key={header.id} >
            {header.masterName === "home" && (
              <li className="sidebar__ul--li-nav-1"></li>
            )}
            <div className="sub-menu-items">
              {(showSubMenuItems[header.id - 1] || !(header.id - 1)) && header.masterPanel.map((panel) => (
                <li key={panel.id} className={`sidebar__ul--li ${(panel.id === 1) && "first"}`}>
                  <NavLink to={`${panel.link}`} className={`sidebar__ul--li-nav ${(panel.id === 1) && "first"}`}>
                    <div className={`sidebar-subitems-icons ${(panel.id === 1) && "first"}`}>
                      <span>{iconComponents[panel.name]}</span>
                    </div>
                    <div className="menu-name">
                      <span>{panel.name}</span>
                    </div>
                  </NavLink>
                </li>
              ))}
              <li className={`sidebar__ul--li ${"last"}`}>
                  <NavLink className="sidebar__ul--li-nav" onClick={() => {
                    setToken(null)
                    localStorage.removeItem("token")
                    toast.success("Logout Successful")
                  }}>
                    <div className={`sidebar-subitems-icons last`} style={{color: "rgba(255, 255, 255, 0.66)"}}>
                      <span>{iconComponents["logout"]}</span>
                    </div>
                    <div className="menu-name" style={{backgroundColor: "#fff", color:"#333333"}} >
                      <span>Logout</span>
                    </div>
                  </NavLink>
                </li>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};
export default Sidebar;
