export const getLoggedInUserDetails = (key) => {
    const user = JSON.parse(localStorage.getItem("user"))?.data
    if (key === "name") return user.name
    if (key === "email") return user.email
    if (key === "id") return user._id
    if (key === "role") return user.role
    return user
}

export const getToken = () => {
    const token = localStorage.getItem("token")
    return token
}